<template>
  <Dialog :open="showDialog" :type="dialogType" :title="dialogTitle" confirmBtnTitle="OK" @close="btnAction()">
    <template #content>{{ dialogMessage }}</template>
  </Dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Dialog from '@/components/layout/Dialog'

export default {
  name: 'AlertDialog',
  components: {
    Dialog,
  },
  computed: {
    ...mapState({
      dialogTitle: state => state.dialog.title,
      dialogMessage: state => state.dialog.message,
      dialogType: state => state.dialog.type,
      redirect: state => state.dialog.redirect,
      dialogErrorCode: state => state.dialog.errorCode,
    }),
    showDialog() {
      return this.$store.state.dialog.showDialog
    },
  },
  methods: {
    ...mapActions(['hideAlert'], 'dialog'),
    async btnAction() {
      if (this.redirect) {
        if (typeof this.redirect === 'string') {
          if (this.redirect === 'login') {
            await this.forceLogout(false)
          }
          this.$router.replace({ name: this.redirect })
        } else if (typeof this.redirect === 'object') {
          if (this.$validate.DataValid(this.redirect.reload)) {
            if (this.redirect.reload === true) {
              window.scrollTo(0, 0)
              this.$router.go()
            } else {
              delete this.redirect['reload']
              this.$router.replace(this.redirect)
            }
          } else {
            if (this.redirect.name === 'login') {
              await this.forceLogout(false)
            }
            this.$router.replace(this.redirect)
          }
        }
      }

      this.hideAlert()
    },
  },
}
</script>
