/* eslint-disable no-unused-vars */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import TokenMixin from '@/mixins/TokenMixin'
import Validator from '@/prototype/Validator'
import Error from '@/views/Error.vue';

Vue.use(VueRouter);
Vue.mixin(TokenMixin)
Vue.use(Validator)
const vm = new Vue();

// -- Must be in order
const routes = [{
  path: '/',
  redirect: 'login',
},
{
  path: '/login',
  name: 'login',
  component: () => import('@/views/pages/Login.vue'),
  meta: {
    layout: 'blank',
    title: '登入',
    skipAuth: true,
  },
},
{
  path: '/no-permission',
  name: 'NoPermission',
  component: () => import('@/views/pages/NoPermission.vue'),
  meta: {
    layout: 'content',
    title: '沒有權限',
    requireAuth: true,
  },
},
{
  path: '/user',
  name: 'UserList',
  component: () => import('@/views/pages/User/UserList.vue'),
  meta: {
    layout: 'content',
    title: '用戶管理',
    requireAuth: true,
    permissionName: "user",
  },
},
{
  path: '/user/create',
  name: 'CreateUser',
  component: () => import('@/views/pages/User/UserDetail.vue'),
  meta: {
    layout: 'content',
    title: '新增用戶',
    requireAuth: true,
    permissionName: "user",
  },
},
{
  path: '/user/:type/:id',
  name: 'UserDetail',
  component: () => import('@/views/pages/User/UserDetail.vue'),
  meta: {
    layout: 'content',
    title: '用戶詳情',
    requireAuth: true,
    permissionName: "user",
  },
},
{
  path: '/admin',
  name: 'AdminList',
  component: () => import('@/views/pages/User/AdminList.vue'),
  meta: {
    layout: 'content',
    title: '所有管理員',
    requireAuth: true,
    permissionName: "admin",
  },
},
{
  path: '/admin/create',
  name: 'CreateAdmin',
  component: () => import('@/views/pages/User/AdminDetail.vue'),
  meta: {
    layout: 'content',
    title: '新增管理員',
    requireAuth: true,
    permissionName: "admin",
  },
},
{
  path: '/admin/detail/:id',
  name: 'AdminDetail',
  component: () => import('@/views/pages/User/AdminDetail.vue'),
  meta: {
    layout: 'content',
    title: '管理員詳情',
    requireAuth: true,
    permissionName: "admin",
  },
},
{
  path: '/permission',
  name: 'PermissionManage',
  component: () => import('@/views/pages/User/Permission.vue'),
  meta: {
    layout: 'content',
    title: '權限設定',
    requireAuth: true,
    permissionName: "admin",
  },
},
{
  path: '/job',
  name: 'JobList',
  component: () => import('@/views/pages/Job/JobList.vue'),
  meta: {
    layout: 'content',
    title: '工作管理',
    requireAuth: true,
    permissionName: "job",
  },
},
{
  path: '/job/create',
  name: 'CreateJob',
  component: () => import('@/views/pages/Job/JobDetail.vue'),
  meta: {
    layout: 'content',
    title: '新增工作',
    requireAuth: true,
    permissionName: "job",
  },
},
{
  path: '/job/detail/:id',
  name: 'JobDetail',
  component: () => import('@/views/pages/Job/JobDetail.vue'),
  meta: {
    layout: 'content',
    title: '工作詳情',
    requireAuth: true,
    permissionName: "job",
  },
},
{
  path: '/order',
  name: 'OrderList',
  component: () => import('@/views/pages/Order/OrderList.vue'),
  meta: {
    layout: 'content',
    title: '課程訂單',
    requireAuth: true,
    permissionName: "order",
  },
},
{
  path: '/order/create/:studentId/:jobId',
  name: 'CreateOrder',
  component: () => import('@/views/pages/Order/CreateOrder.vue'),
  meta: {
    layout: 'content',
    title: '新增課程訂單',
    requireAuth: true,
    permissionName: "order",
  },
},
{
  path: '/order/detail/:id',
  name: 'OrderDetail',
  component: () => import('@/views/pages/Order/OrderDetail.vue'),
  meta: {
    layout: 'content',
    title: '訂單詳情',
    requireAuth: true,
    permissionName: "order",
  },
},
{
  path: '/coupon',
  name: 'CouponList',
  component: () => import('@/views/pages/Promotion/CouponList.vue'),
  meta: {
    layout: 'content',
    title: '優惠券',
    requireAuth: true,
    permissionName: "promotion",
  },
},
{
  path: '/coupon/create',
  name: 'CreateCoupon',
  component: () => import('@/views/pages/Promotion/CouponDetail.vue'),
  meta: {
    layout: 'content',
    title: '新增優惠券',
    requireAuth: true,
    permissionName: "promotion",
  },
},
{
  path: '/coupon/detail/:id',
  name: 'CouponDetail',
  component: () => import('@/views/pages/Promotion/CouponDetail.vue'),
  meta: {
    layout: 'content',
    title: '優惠券詳情',
    requireAuth: true,
    permissionName: "promotion",
  },
},
{
  path: '/notification',
  name: 'Notification',
  component: () => import('@/views/pages/Promotion/Notification.vue'),
  meta: {
    layout: 'content',
    title: '推送通知',
    requireAuth: true,
    permissionName: "promotion",
  },
},
{
  path: '/redeem',
  name: 'RedeemList',
  component: () => import('@/views/pages/Gift/RedeemList.vue'),
  meta: {
    layout: 'content',
    title: '換領預約',
    requireAuth: true,
    permissionName: "gift",
  },
},
{
  path: '/redeem/create/:giftId?',
  name: 'CreateRedeem',
  component: () => import('@/views/pages/Gift/RedeemDetail.vue'),
  meta: {
    layout: 'content',
    title: '新增換領',
    requireAuth: true,
    permissionName: "gift",
  },
},
{
  path: '/redeem/detail/:id',
  name: 'RedeemDetail',
  component: () => import('@/views/pages/Gift/RedeemDetail.vue'),
  meta: {
    layout: 'content',
    title: '換領詳情',
    requireAuth: true,
    permissionName: "gift",
  },
},

{
  path: '/gift',
  name: 'GiftList',
  component: () => import('@/views/pages/Gift/GiftList.vue'),
  meta: {
    layout: 'content',
    title: '禮品設定',
    requireAuth: true,
    permissionName: "gift",
  },
},
{
  path: '/gift/create',
  name: 'CreateGift',
  component: () => import('@/views/pages/Gift/GiftDetail.vue'),
  meta: {
    layout: 'content',
    title: '新增禮品',
    requireAuth: true,
    permissionName: "gift",
  },
},
{
  path: '/gift/detail/:id',
  name: 'GiftDetail',
  component: () => import('@/views/pages/Gift/GiftDetail.vue'),
  meta: {
    layout: 'content',
    title: '禮品詳情',
    requireAuth: true,
    permissionName: "gift",
  },
},
{
  path: '/enquiry',
  name: 'EnquiryList',
  component: () => import('@/views/pages/Other/EnquiryList.vue'),
  meta: {
    layout: 'content',
    title: '查詢',
    requireAuth: true,
    permissionName: "enquiry",
  },
},
{
  path: '/report',
  name: 'Report',
  component: () => import('@/views/pages/Other/Report.vue'),
  meta: {
    layout: 'content',
    title: '報表',
    requireAuth: true,
    permissionName: "report",
  },
},
{
  path: '/subscribe',
  name: 'SubscribeList',
  component: () => import('@/views/pages/Other/SubscribeList.vue'),
  meta: {
    layout: 'content',
    title: '訂閱',
    requireAuth: true,
    permissionName: "subscribe",
  },
},
{
  path: '/setting',
  name: 'Setting',
  component: () => import('@/views/pages/Other/Setting.vue'),
  meta: {
    layout: 'content',
    title: '設定',
    requireAuth: true,
    permissionName: "setting",
  },
},
{
  path: '/setting/create',
  name: 'CreateStudentGroup',
  component: () => import('@/views/pages/Other/SettingDetail.vue'),
  meta: {
    layout: 'content',
    title: '新增用戶組別',
    requireAuth: true,
    permissionName: "setting",
  },
},
{
  path: '/setting/detail/:id',
  name: 'StudentGroupDetail',
  component: () => import('@/views/pages/Other/SettingDetail.vue'),
  meta: {
    layout: 'content',
    title: '用戶組別詳情',
    requireAuth: true,
    permissionName: "setting",
  },
},
{
  path: '*',
  name: 'Error',
  component: Error,
  meta: { title: 'Error' },
},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('setInitLoading', false);
  store.dispatch('setLoading', false);
  window.scrollTo(0, 0);

  if (to.meta.requireAuth) {
    const userData = vm.getCurrentUserData();
    if (vm.$validate.DataValid(userData)) {
      const userPermission = vm.getUserPermission();
      if (to.meta.permissionName) {
        if (userPermission.length && userPermission.includes(to.meta.permissionName)) {
          next();
        } else {
          next('/no-permission');
        }
      } else {
        next();
      }
    } else {
      next('/login');
    }
  } else if (to.meta.skipAuth) {
    const userData = vm.getCurrentUserData();
    if (vm.$validate.DataValid(userData) && userData.user_type) {
      const userPermission = vm.getUserPermission();

      if (to.name === 'login') {
        // --- find default page
        if (userPermission.length) {
          const found = routes.find(el => el.meta && el.meta.permissionName === userPermission[0]);
          if (found) {
            next(found.path);
          } else {
            next('/no-permission');
          }
        } else {
          next('/no-permission');
        }
      } else {
        if (to.meta.permissionName) {
          if (userPermission.length && userPermission.includes(to.meta.permissionName)) {
            next();
          } else {
            next('/no-permission');
          }
        } else {
          next();
        }
      }
    } else {
      next();
    }
  } else {
    next();
  }
})

const DEFAULT_TITLE = 'Tutor Circle CMS'

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = (`${to.meta.title} - ` || '') + DEFAULT_TITLE
  })
})

export default router
