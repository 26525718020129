const displayError = (res) => {
    if (res.data !== undefined && res.data !== null) {
        if (typeof res.data === 'string') {
            if (res.data === "token verification fail") {
                return "tokenExpired";
            } else if (res.data === "permission denied") {
                return "noPermission";
            }
            return res.data;
        } else {
            return JSON.stringify(res.data);
        }
    }

    return JSON.stringify(res);
}

export default {
    install(Vue, options) {
        Vue.prototype.$Fetcher = new (function () {
            const DataValid = Vue.prototype.$validate.DataValid;
            const devLog = Vue.prototype.$common.log;

            this.Login = async function (username, password) {
                try {
                    const payload = {
                        username,
                        password
                    };
                    const data = await Vue.prototype.$XHR.api('cms_admin_login', payload);
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: Login', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: Login');
                        return Promise.reject('發生錯誤，請與我們聯絡');
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: Login', res);
                    return Promise.reject(displayError(res));
                }
            }

            this.Logout = async function (uid, token) {
                try {
                    const payload = {
                        admin_id: uid,
                        token
                    };
                    const data = await Vue.prototype.$XHR.api('cms_admin_logout', payload);
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: Logout');
                        return Promise.resolve(true);
                    } else {
                        devLog('----------> XHR [NULL]: Logout');
                        return Promise.reject('發生錯誤，請與我們聯絡');
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: Logout', res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetSystemPermission = async function (uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                try {
                    const payload = {
                        validate_token: {
                            token,
                            admin_id: uid
                        }
                    };
                    const data = await Vue.prototype.$XHR.api('get_admin_permission', payload);
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: GetSystemPermission', data);
                        const permissionList = ['user', 'admin', 'job', 'order', 'promotion', 'gift', 'enquiry', 'report', 'subscribe', 'setting']
                        const temp = {
                            admin: [],
                            staff: [],
                        }
                        if (data['admin']) {
                            permissionList.forEach(key => {
                                switch (key) {
                                    case 'user':
                                        if (data['admin'].user_management) {
                                            temp.admin.push(key)
                                        }
                                        break
                                    case 'job':
                                        if (data['admin'].job_management) {
                                            temp.admin.push(key)
                                        }
                                        break
                                    case 'order':
                                        if (data['admin'].order_management) {
                                            temp.admin.push(key)
                                        }
                                        break
                                    case 'promotion':
                                        if (data['admin'].promotion_management) {
                                            temp.admin.push(key)
                                        }
                                        break
                                    default:
                                        temp.admin.push(key)
                                }
                            })
                        }
                        if (data['staff']) {
                            permissionList.forEach(key => {
                                switch (key) {
                                    case 'user':
                                        if (data['staff'].user_management) {
                                            temp.staff.push(key)
                                        }
                                        break
                                    case 'admin':
                                        break;
                                    case 'job':
                                        if (data['staff'].job_management) {
                                            temp.staff.push(key)
                                        }
                                        break
                                    case 'order':
                                        if (data['staff'].order_management) {
                                            temp.staff.push(key)
                                        }
                                        break
                                    case 'promotion':
                                        if (data['staff'].promotion_management) {
                                            temp.staff.push(key)
                                        }
                                        break
                                    default:
                                        temp.staff.push(key)
                                }
                            })
                        }
                        return Promise.resolve(temp);
                    } else {
                        devLog('----------> XHR [NULL]: GetSystemPermission');
                        return Promise.reject('發生錯誤，請與我們聯絡');
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetSystemPermission', res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateSystemPermission = async function (upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }

                const payload = {
                    ...upload,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    await Vue.prototype.$XHR.api('update_admin_permission', payload);
                    devLog('----------> XHR [SUCCESS]: UpdateSystemPermission');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateSystemPermission', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            /**
             * 
             * @param {string} username 
             * @param {string} permission_type - admin | staff 
             * @param {string} password 
             * @param {string} confirm_password 
             */
            this.NewAdmin = async function (upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }

                const payload = {
                    ...upload,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    await Vue.prototype.$XHR.api('cms_register_admin', payload);
                    devLog('----------> XHR [SUCCESS]: NewAdmin');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewAdmin', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else if (res.data === "username duplicate") {
                        return Promise.reject("用戶名稱已存在");
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetAdmins = async function (filters = {}, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    ...filters,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_admin_list', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetAdminList', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetAdminList');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetAdminList', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.UpdateAdmin = async function (id, upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }

                const payload = {
                    id,
                    ...upload,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    await Vue.prototype.$XHR.api('cms_update_admin', payload);
                    devLog('----------> XHR [SUCCESS]: UpdateAdmin');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateAdmin', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else if (res.data === "username duplicate") {
                        return Promise.reject("用戶名稱已存在");
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.DeleteAdmin = async function (remove_id, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    id: remove_id,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    await Vue.prototype.$XHR.api('cms_remove_admin', payload);
                    devLog('----------> XHR [SUCCESS]: DeleteAdmin');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: DeleteAdmin', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetAdminById = async function (id, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    id,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_admin_by_id', payload);
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: GetAdminById', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetAdminById');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetAdminById', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetUsers = async function (filters = {}, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    ...filters,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_student_tutor_list', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetUsers');
                        devLog(data)
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetUsers');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetUsers', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            /**
             * 
             * @param {string} user_img 
             * @param {string} status
             * @param {string} name 
             * @param {string} email 
             * @param {string} phone 
             * @param {number} student_group - group id
             */
            this.NewStudent = async function (upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }

                const payload = {
                    ...upload,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    await Vue.prototype.$XHR.api('cms_create_student', payload);
                    devLog('----------> XHR [SUCCESS]: NewStudent');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewStudent', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else if (res.data === "phone duplicate") {
                        return Promise.reject("電話號碼已存在");
                    } else if (res.data === "student group not found") {
                        return Promise.reject("用戶組別已不存在，請另選其他用戶組別");
                    } else if (res.data === "referral code does not exists" || res.data === "cannot use this referral code") {
                        return Promise.reject("推薦碼無效");
                    } else if (res.data === "referral code generation fail") {
                        return Promise.reject("未能生成推薦碼，請聯絡我們");
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.UpdateStudent = async function (id, upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }

                const payload = {
                    id,
                    ...upload,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    await Vue.prototype.$XHR.api('update_student', payload);
                    devLog('----------> XHR [SUCCESS]: UpdateStudent');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateStudent', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else if (res.data === "phone duplicate") {
                        return Promise.reject("電話號碼已存在");
                    } else if (res.data === "student group not found") {
                        return Promise.reject("用戶組別已不存在，請另選其他用戶組別");
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetStudentById = async function (id, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    id,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_student_by_id', payload);
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: GetStudentById', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetStudentById');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetStudentById', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetStudents = async function (filters = {}, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    ...filters,
                    status: "active",
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_student_list', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetStudents', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetStudents');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetStudents', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            /**
             * 
             * @param {string} user_img 
             * @param {string} status
             * @param {string} name 
             * @param {string} email 
             * @param {string} phone 
             * @param {string} lesson_category 
             * @param {Array<string>} subject
             * @param {string} qualification
             * @param {string} gender
             * @param {string} age
             * @param {string} experience
             */
            this.NewTutor = async function (upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }

                const payload = {
                    ...upload,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    await Vue.prototype.$XHR.api('cms_create_tutor', payload);
                    devLog('----------> XHR [SUCCESS]: NewTutor');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewTutor', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else if (res.data === "phone duplicate") {
                        return Promise.reject("電話號碼已存在");
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.UpdateTutor = async function (id, upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }

                const payload = {
                    id,
                    ...upload,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    await Vue.prototype.$XHR.api('update_tutor', payload);
                    devLog('----------> XHR [SUCCESS]: UpdateTutor');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateTutor', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else if (res.data === "phone duplicate") {
                        return Promise.reject("電話號碼已存在");
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetTutorById = async function (id, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    id,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_tutor_by_id', payload);
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: GetTutorById', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetTutorById');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetTutorById', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetTutors = async function (filters = {}, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    ...filters,
                    status: "active",
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_tutor_list', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetTutors', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetTutors');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetTutors', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            /**
             * 
             * @param {string} name 
             */
            this.NewStudentGroup = async function (upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }

                const payload = {
                    ...upload,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    await Vue.prototype.$XHR.api('add_student_group', payload);
                    devLog('----------> XHR [SUCCESS]: NewStudentGroup');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewStudentGroup', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.UpdateStudentGroup = async function (id, upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }

                const payload = {
                    id,
                    ...upload,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    await Vue.prototype.$XHR.api('update_student_group', payload);
                    devLog('----------> XHR [SUCCESS]: UpdateStudentGroup');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateStudentGroup', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.DeleteStudentGroup = async function (remove_id, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    id: remove_id,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    await Vue.prototype.$XHR.api('remove_student_group', payload);
                    devLog('----------> XHR [SUCCESS]: DeleteStudentGroup');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: DeleteStudentGroup', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetStudentGroupById = async function (id, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    id,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_student_group_by_id', payload);
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: GetStudentGroupById', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetStudentGroupById');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetStudentGroupById', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetStudentGroups = async function (filters = {}, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    ...filters,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_student_group_list', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetStudentGroups', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetStudentGroups');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetStudentGroups', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetEnquiries = async function (filters = {}, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    ...filters,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_contact_us_list', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetEnquiries', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetEnquiries');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetEnquiries', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetSubscribes = async function (filters = {}, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    ...filters,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_subscribe_list', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetSubscribes', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetSubscribes');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetSubscribes', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            /**
             * 
             * @param {string} name 
             * @param {string} status 
             * @param {string} image 
             * @param {string} code 
             * @param {string} discount_type 
             * @param {number} discount_value
             * @param {boolean} reusable
             * @param {string} start_date 
             * @param {string} expiry_date 
             */
            this.NewCoupon = async function (upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }

                const payload = {
                    ...upload,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    await Vue.prototype.$XHR.api('cms_add_promo_code', payload);
                    devLog('----------> XHR [SUCCESS]: NewCoupon');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewCoupon', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else if (res.data === "promo code duplicate") {
                        return Promise.reject("優惠碼已存在");
                    } else if (res.data === "price discount value must larger than 0" || res.data === "discount value must be between 0 and 100") {
                        return Promise.reject("優惠數值無效");
                    } else if (res.data === "promo code generation fail") {
                        return Promise.reject("未能生成優惠碼，請聯絡我們");
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.UpdateCoupon = async function (id, upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }

                const payload = {
                    id,
                    ...upload,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    await Vue.prototype.$XHR.api('cms_update_promo_code', payload);
                    devLog('----------> XHR [SUCCESS]: UpdateCoupon');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateCoupon', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else if (res.data === "promo code duplicate") {
                        return Promise.reject("優惠碼已存在");
                    } else if (res.data === "price discount value must larger than 0" || res.data === "discount value must be between 0 and 100") {
                        return Promise.reject("優惠數值無效");
                    } else if (res.data === "promo code generation fail") {
                        return Promise.reject("未能生成優惠碼，請聯絡我們");
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetCoupons = async function (filters = {}, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    ...filters,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_promo_code_list', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetCoupons', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetCoupons');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetCoupons', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetCouponById = async function (id, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    id,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_promo_code_by_id', payload);
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: GetCouponById', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetCouponById');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetCouponById', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            /**
             * 
             * @param {number} student_id 
             * @param {string} lesson_category 
             * @param {Array<string>} subject 
             * @param {string} qualification 
             * @param {string} gender 
             * @param {string} age
             * @param {string} experience
             * @param {number} tutor_id
             * @param {Array<{date: '', start_time: '', end_time: ''}>} course_timetable 
             */
            this.NewJob = async function (upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }

                const payload = {
                    ...upload,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    await Vue.prototype.$XHR.api('cms_add_job_request', payload);
                    devLog('----------> XHR [SUCCESS]: NewJob');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewJob', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else if (res.data === "student not found") {
                        return Promise.reject("找不到學生");
                    } else if (res.data === "tutor not found") {
                        return Promise.reject("找不到導師");
                    } else if (res.data === "invalid date" || res.data === "end time cannot earlier than start time") {
                        return Promise.reject("課堂日期/時間無效");
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.UpdateJob = async function (id, upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }

                const payload = {
                    id,
                    ...upload,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    await Vue.prototype.$XHR.api('cms_update_job_request', payload);
                    devLog('----------> XHR [SUCCESS]: UpdateJob');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateJob', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else if (res.data === "student not found") {
                        return Promise.reject("找不到學生");
                    } else if (res.data === "tutor not found") {
                        return Promise.reject("找不到導師");
                    } else if (res.data === "invalid date" || res.data === "end time cannot earlier than start time") {
                        return Promise.reject("課堂日期/時間無效");
                    } else if (res.data === "cannot add or remove timeslot") {
                        return Promise.reject("現有課堂數量需與原有課堂數量相同");
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetJobs = async function (filters = {}, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    ...filters,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_job_request_list', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetJobs', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetJobs');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetJobs', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }
            
            this.GetJobById = async function (id, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    id,
                    check_edit_timetable_only: true,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_job_request_by_id', payload);
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: GetJobById', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetJobById');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetJobById', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            /**
             * 
             * @param {number} job_request_id
             * @param {string} payment_method
             * @param {number} lesson_price
             * @param {string} promo_code - optional 
             * @param {number} course_count
             * @param {number} total
             */
            this.NewOrder = async function (upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }

                const payload = {
                    ...upload,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    const data = await Vue.prototype.$XHR.api('add_order', payload);
                    devLog('----------> XHR [SUCCESS]: NewOrder');
                    return Promise.resolve(data.id);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewOrder', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else if (res.data === "job request not found") {
                        return Promise.reject("找不到相關課堂工作");
                    } else if (res.data === "order has been created already") {
                        return Promise.reject("此工作已建立訂單");
                    } else if (res.data === "please add at least one timetable") {
                        return Promise.reject("至少新增一個課堂");
                    } else if (res.data === "number of timetable not match") {
                        return Promise.reject("堂數與工作的課堂必須相同");
                    } else if (res.data === "please select a tutor") {
                        return Promise.reject("請回到工作選擇導師");
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetOrders = async function (filters = {}, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    ...filters,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_order_list', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetOrders', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetOrders');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetOrders', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetOrderById = async function (id, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    id,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_order_by_id', payload);
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: GetOrderById', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetOrderById');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetOrderById', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.UpdateOrder = async function (id, upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }

                const payload = {
                    id,
                    ...upload,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    await Vue.prototype.$XHR.api('update_order', payload);
                    devLog('----------> XHR [SUCCESS]: UpdateOrder');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateOrder', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else if (res.data === "order not found") {
                        return Promise.reject("找不到訂單");
                    } else if (res.data === "cannot update payment method") {
                        return Promise.reject("不可更新付款方式");
                    } else if (res.data === "cannot update lesson price") {
                        return Promise.reject("不可更新價錢");
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetOrderReceipt = async function (order_id, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    order_id,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_receipt_pdf', payload);
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: GetOrderReceipt', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetOrderReceipt');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetOrderReceipt', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            /**
             * 
             * @param {string} name
             * @param {string} gift_image
             * @param {number} point_required
             * @param {string} description
             * @param {number} inventory
             */
            this.NewGift = async function (upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }

                const payload = {
                    ...upload,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    const data = await Vue.prototype.$XHR.api('add_gift', payload);
                    devLog('----------> XHR [SUCCESS]: NewGift');
                    return Promise.resolve(data.id);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewGift', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.UpdateGift = async function (id, upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }

                const payload = {
                    id,
                    ...upload,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    await Vue.prototype.$XHR.api('update_gift', payload);
                    devLog('----------> XHR [SUCCESS]: UpdateGift');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateGift', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else if (res.data === "gift not found") {
                        return Promise.reject("找不到禮品");
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetGiftById = async function (id, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    id,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_gift_by_id', payload);
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: GetGiftById', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetGiftById');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetGiftById', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetGifts = async function (filters = {}, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    ...filters,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_gift_list', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetGifts', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetGifts');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetGifts', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            /**
             * 
             * @param {number} student_id
             * @param {number} gift_id
             * @param {number} amount
             * @param {string} redeem_date
             * @param {string} redeem_time
             */
            this.NewRedeem = async function (upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }
                if (upload["redeem_status"]) {
                    delete upload["redeem_status"];
                }

                const payload = {
                    ...upload,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    const data = await Vue.prototype.$XHR.api('add_gift_redeem', payload);
                    devLog('----------> XHR [SUCCESS]: NewRedeem');
                    return Promise.resolve(data.id);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewRedeem', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else if (res.data === "student not found") {
                        return Promise.reject("找不到用戶");
                    } else if (res.data === "gift not found") {
                        return Promise.reject("找不到禮品");
                    } else if (res.data === "inventory not enough") {
                        return Promise.reject("沒有足夠存庫");
                    } else if (res.data === "point not enough") {
                        return Promise.reject("該用戶的積分不足");
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.UpdateRedeem = async function (id, upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }
                if (upload["gift_id"]) {
                    delete upload["gift_id"];
                }
                if (upload["student_id"]) {
                    delete upload["student_id"];
                }
                if (upload["amount"]) {
                    delete upload["amount"];
                }

                const payload = {
                    id,
                    ...upload,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    await Vue.prototype.$XHR.api('update_gift_redeem', payload);
                    devLog('----------> XHR [SUCCESS]: UpdateRedeem');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateRedeem', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else if (res.data === "gift redeem record not found") {
                        return Promise.reject("找不到換領記錄");
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetRedeemById = async function (id, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    id,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_gift_redeem_by_id', payload);
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: GetRedeemById', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetRedeemById');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetRedeemById', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetRedeems = async function (filters = {}, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    ...filters,
                    validate_token: {
                        token,
                        admin_id: uid
                    }
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_gift_redeem_list', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetRedeems', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetRedeems');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetRedeems', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }
        });
    },
}