<script>
import Cookies from 'js-cookie'

export default {
  name: 'TokenMixin',
  methods: {
    getCurrentUserData() {
      const data = Cookies.get('TUTOR_CIRCLE_CMS_LID')

      if (data !== undefined && data !== null && data !== '') {
        return JSON.parse(Cookies.get('TUTOR_CIRCLE_CMS_LID'))
      }

      return {}
    },
    /**
     * @param {Object} data - { id: -1, user_type: '', username: '', token: '' }
     */
    setUserData(data) {
      const userData = this.getCurrentUserData()
      if (this.$validate.DataValid(userData)) {
        Cookies.remove('TUTOR_CIRCLE_CMS_LID')
      }

      Cookies.set('TUTOR_CIRCLE_CMS_LID', JSON.stringify(data), {
        path: '/',
        expires: new Date(new Date().getTime() + 3 * 60 * 60 * 1000), // expires after 2 hour
        sameSite: 'strict',
        secure: true, // TODO: https --> true
      })
    },
    getUserType() {
      const userData = this.getCurrentUserData()
      return userData.user_type
    },
    removeUserCookies() {
      const userData = this.getCurrentUserData()
      if (this.$validate.DataValid(userData)) {
        Cookies.remove('TUTOR_CIRCLE_CMS_LID')
      }
    },
    checkUserData() {
      let resultType = false
      const userData = this.getCurrentUserData()

      if (this.$validate.DataValid(userData)) {
        if (this.$validate.DataValid(userData.id) && this.$validate.DataValid(userData.token)) {
          resultType = true
        }
      }

      if (!resultType) {
        this.forceLogout()
      }

      return resultType
    },

    async forceLogout(alert = true) {
      this.$store.dispatch('setInitLoading', true)

      const userData = this.getCurrentUserData()
      if (this.$validate.DataValid(userData.id) && userData.token) {
        try {
          await this.$Fetcher.Logout(userData.id, userData.token)
          this._finishLogout(alert)
        } catch {
          this._finishLogout(alert)
        }
      } else {
        this._finishLogout(alert)
      }
    },
    _finishLogout(alert = true) {
      this.removeUserCookies()
      if (alert === true) {
        this.$store.dispatch('toggleSnack', {
          show: true,
          message: '登入過期，請重新登入',
          type: 'error',
          refresh: false,
          redirect: 'login',
        })
      } else {
        this.$router.replace({ name: 'login' })
        this.$store.dispatch('setInitLoading', false)
      }
    },

    getUserPermission() {
      const userData = this.getCurrentUserData()
      let permission = localStorage.getItem('TUTOR_CIRCLE_PERMISSION')
      if (permission) {
        permission = JSON.parse(permission)
      }

      if (userData.user_type && permission && permission[userData.user_type]) {
        return permission[userData.user_type]
      }

      return []
    },
    setSystemPermission(permission) {
      localStorage.setItem('TUTOR_CIRCLE_PERMISSION', JSON.stringify(permission));
    },
  },
}
</script>
