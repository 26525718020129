import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import api from './api'
import TokenMixin from '@/mixins/TokenMixin'
import PageHeader from './components/layout/PageHeader.vue'
import Fetcher from './prototype/Fetcher'
import CommonFn from './prototype/CommonFn'
import Formatter from './prototype/Formatter'
import Validator from './prototype/Validator'

Vue.config.productionTip = false

Vue.use(api)
Vue.use(CommonFn)
Vue.use(Validator)
Vue.use(Formatter)
Vue.use(Fetcher)
Vue.mixin(TokenMixin)

Vue.component('PageHeader', PageHeader)

Vue.prototype.$mediaPath = 'https://tcapi.innpressionhost.com/media/'; // TODO


const statusOptions = [
  { text: '啟用中', value: 'active' },
  { text: '停用', value: 'inactive' },
];
Vue.prototype.$statusOptions = statusOptions;
Vue.filter('formatStatus', function (value) {
  if (!value) return '';
  const target = statusOptions.find(e => e.value === value);
  return target ? target.text : value;
})

const userTypeOptions = [
  { text: '學生', value: 'student' },
  { text: '導師', value: 'tutor' },
];
Vue.prototype.$userTypeOptions = userTypeOptions;
Vue.filter('formatUserType', function (value) {
  if (!value) return '';
  const target = userTypeOptions.find(e => e.value === value);
  return target ? target.text : value;
})

const adminTypeOptions = [
  { text: '管理員', value: 'admin' },
  { text: '員工', value: 'staff' },
];
Vue.prototype.$adminTypeOptions = adminTypeOptions;
Vue.filter('formatAdminType', function (value) {
  if (!value) return '';
  const target = adminTypeOptions.find(e => e.value === value);
  return target ? target.text : value;
})

const lessonCategoryOptions = [
  { value: 'all', text: '全科補習' },
      { value: 'homework', text: '功課輔導' },
];
Vue.prototype.$lessonCategoryOptions = lessonCategoryOptions;
Vue.filter('formatLessonCategory', function (value) {
  if (!value) return '';
  const target = lessonCategoryOptions.find(e => e.value === value);
  return target ? target.text : value;
})

const subjectOptions = [
  { value: 'all_subject', text: '全科' },
  { value: 'chi', text: '中文' },
  { value: 'eng', text: '英文' },
  { value: 'math', text: '數學' },
  { value: 'phy', text: '物理' },
  { value: 'chem', text: '化學' },
  { value: 'bio', text: '生物' },
  { value: 'econ', text: '經濟' },
  { value: 'chist', text: '中國歷史' },
  { value: 'hist', text: '歷史' },
];
Vue.prototype.$subjectOptions = subjectOptions;
Vue.filter('formatSubject', function (value) {
  if (!value) return '';
  const target = subjectOptions.find(e => e.value === value);
  return target ? target.text : value;
})

const qualificationOptions = [
  { value: 'tertiary_student', text: '大專生' },
  { value: 'bachelor_degree', text: '學士學歷' },
  { value: 'master_degree', text: '碩士學歷或以上' },
];
Vue.prototype.$qualificationOptions = qualificationOptions;

const genderOptions = [
  { value: 'M', text: '男' },
  { value: 'F', text: '女' },
  { value: 'all', text: '男女皆可' },
]
Vue.prototype.$genderOptions = genderOptions;
const ageOptions = [
  { value: 'all', text: '不限年齡' },
  { value: '20', text: '20-25' },
  { value: '26', text: '26-30' },
  { value: '31', text: '31-35' },
  { value: '36', text: '36-40' },
  { value: '40', text: '40＋' },
]
Vue.prototype.$ageOptions = ageOptions;
const experienceOptions = [
  { value: 'no_experience', text: '沒教學經驗' },
  { value: 'one_year_experience', text: '一年經驗或以上' },
]
Vue.prototype.$experienceOptions = experienceOptions;

const orderStatusOptions = [
  { text: '待付款', value: 'awaiting_payment' },
  { text: '已確定', value: 'confirmed' },
  { text: '已取消', value: 'cancelled' },
  { text: '已退款', value: 'refunded' },
];
Vue.prototype.$orderStatusOptions = orderStatusOptions;
Vue.filter('formatOrderStatus', function (value) {
  if (!value) return '';
  const target = orderStatusOptions.find(e => e.value === value);
  return target ? target.text : value;
})

const redeemStatusOptions = [
  { text: '已換領', value: 'redeemed' },
  { text: '待取貨', value: 'delivering' },
  { text: '已取消', value: 'cancelled' },
];
Vue.prototype.$redeemStatusOptions = redeemStatusOptions;
Vue.filter('formatRedeemStatus', function (value) {
  if (!value) return '';
  const target = redeemStatusOptions.find(e => e.value === value);
  return target ? target.text : value;
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
