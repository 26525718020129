<template>
  <Dialog title="資料未儲存，確定離開頁面？" :open="showDialog" enableCancel @close="_dialogAction">
    <template #content>資料未儲存，操作將被作廢</template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/layout/Dialog.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ConfirmLeaveDialog',
  components: {
    Dialog,
  },
  computed: {
    ...mapState({
      toLink: state => state.leaveDialog.toLink,
    }),
    showDialog() {
      return this.$store.state.leaveDialog.showDialog
    },
  },
  data: () => ({}),
  methods: {
    ...mapActions(['hideLeaveDialog'], 'leaveDialog'),
    async _dialogAction(action) {
      if (action === true) {
        if (this.toLink) {
          await this.$store.dispatch('setDataIsUpdated', false)
          this.$router.replace(this.toLink)
          this.hideLeaveDialog()
        }
      } else {
        this.hideLeaveDialog()
      }
    },
  },
}
</script>
