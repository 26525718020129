const state = {
    alertMessage: { // Snack props 
        show: false,
        message: '',
        type: 'success', // success, info, warning , error, processing
        refresh: false,
        redirect: null,
        closeManual: false
    },
    initLoading: false, // show init loading overlay or not
    isLoading: false, // tells submission/upload is processing or not
    isUploading: false, // tells upload is processing or not
    dataIsUpdated: false, // tells details is edited without saving or not
}

const getters = {
    initLoading: state => state.initLoading,
    alertMessage: state => state.alertMessage,
    isLoading: state => state.isLoading,
    isUploading: state => state.isUploading,
    dataIsUpdated: state => state.dataIsUpdated,
}

const actions = {
    toggleSnack({ commit }, data = null) {
        if (data === null) {
            commit('toggleAlert', {
                show: false,
                refresh: false,
                redirect: '',
                closeManual: false
            })
        } else {
            const passData = {
                show: true,
                message: '',
                type: 'success',
                refresh: false,
                redirect: null,
                closeManual: false
            };

            for (const key in passData) {
                if (data[key] !== undefined) {
                    passData[key] = data[key];
                }
            }

            switch (data.message) {
                case 'processing':
                    passData.message = '資料處理中，請稍候...';
                    passData.type = 'error';
                    break;
                case 'required':
                    passData.message = '請填滿所有必填項目並確保輸入無誤';
                    passData.type = 'error';
                    break;
                case 'invValue':
                    passData.message = '請確保輸入無誤';
                    passData.type = 'error';
                    break;
                case 'noPermission':
                    passData.message = '你沒有權限進行此動作';
                    passData.type = 'error';
                    break;
                case 'tokenExpired':
                    passData.message = '登入過期，請重新登入';
                    passData.type = 'error';
                    break;
            }
            commit('toggleAlert', passData)
        }
    },
    setInitLoading({ commit }, data = false) {
        commit('setInitLoading', data)
    },
    setLoading({ commit }, data = false) {
        commit('setLoading', data)
    },
    setUploadLoading({ commit }, data = false) {
        commit('setUploadLoading', data)
    },
    setDataIsUpdated({ commit }, data = false) {
        commit('setDataIsUpdated', data)
    },
}

const mutations = {
    toggleAlert: function (state, data) {
        const temp = JSON.parse(JSON.stringify(state.alertMessage));
        for (const key in temp) {
            if (data[key] !== undefined) {
                temp[key] = data[key];
            }
        }
        state.alertMessage = temp;
    },
    setInitLoading: function (state, bool) {
        state.initLoading = bool;
    },
    setLoading: function (state, bool) {
        state.isLoading = bool;
    },
    setUploadLoading: function (state, bool) {
        state.isUploading = bool
    },
    setDataIsUpdated: function (state, bool) {
        state.dataIsUpdated = bool
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}