<template>
  <div class="page-header">
    <div class="left">
      <v-btn v-if="showBackButton" plain depressed color="black" text @click="$router.back()" class="mr-2" :disabled="isLoading">
        <v-icon left class="back-button-icon">{{ mdiChevronLeft }}</v-icon>
        上一頁
      </v-btn>
      <h3 v-if="title">{{ title }}</h3>
    </div>
    <div class="right">
      <slot name="rightSlot"></slot>
    </div>
  </div>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
export default {
  name: 'PageHeader',
  props: {
    showBackButton: {
      required: false,
      type: Boolean,
      default: false,
    },
    isLoading: {
      required: false,
      type: Boolean,
      default: false,
    },
    title: {
      required: false,
      type: String,
      default: ''
    }
  },
  data: () => ({
    mdiChevronLeft,
  }),
}
</script>

<style lang="scss" scoped>
.back-button-icon {
  font-size: 14px !important;
}

.page-header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  
  &>.left {
    margin-right: 15px;
    display: flex;
    align-items: center;

    &>* {
      margin-bottom: 20px;
    }
  }

  &>.right {
    display: flex;
    align-items: center;
    &>* {
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 490px) {
  .page-header {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
</style>
