<template>
  <v-app>
    <v-main class="pa-0" style="background-color: #FAFAFA;">
      <router-view></router-view>
      <snack :type="alertType" :text="alertText" :show="showAlert" :closeManual="closeManual"></snack>
    </v-main>
  </v-app>
</template>

<script>
import Snack from '@/components/Snack'
export default {
  name: 'Blank',
  components: {
    Snack,
  },
  computed: {
    alertType() {
      return this.$store.getters.alertMessage.type;
    },
    alertText() {
      return this.$store.getters.alertMessage.message;
    },
    showAlert() {
      return this.$store.getters.alertMessage.show;
    },
    closeManual() {
      return this.$store.getters.alertMessage.closeManual
    },
  },
}
</script>
