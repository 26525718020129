import { render, staticRenderFns } from "./VerticalNavMenu.vue?vue&type=template&id=e0c411a8&scoped=true&"
import script from "./VerticalNavMenu.vue?vue&type=script&lang=js&"
export * from "./VerticalNavMenu.vue?vue&type=script&lang=js&"
import style0 from "./VerticalNavMenu.vue?vue&type=style&index=0&lang=css&"
import style1 from "./VerticalNavMenu.vue?vue&type=style&index=1&id=e0c411a8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0c411a8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VList,VNavigationDrawer,VSubheader})
