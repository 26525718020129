<template>
  <v-container class="alert-pop">
    <v-alert width="250px" v-model="showStatus" :type="alertType" transition="slide-x-reverse-transition">
      <template #prepend v-if="alertIcon !== null">
        <v-icon class="mr-2">{{ alertIcon }}</v-icon>
      </template>
      {{ text }}
    </v-alert>
  </v-container>
</template>

<script>
import { mdiProgressUpload } from '@mdi/js'
export default {
  name: 'Snack',
  props: ['type', 'show', 'text', 'closeManual'],
  data: () => ({
    timeout: -1,
    timeOutClock: null,
    alertType: 'success',
    alertIcon: null,

    icon: {
      mdiProgressUpload,
    },
  }),
  watch: {
    type: function(newVal) {
      this.alertIcon = null
      if (newVal === 'processing') {
        this.alertType = 'info'
        this.alertIcon = this.icon.mdiProgressUpload
      } else {
        this.alertType = newVal
      }
    },
  },
  computed: {
    showStatus() {
      if (this.show) {
        if (this.timeOutClock != null) {
          clearTimeout(this.timeOutClock)
        }

        if (!this.closeManual) {
          this.timeOutClock = setTimeout(() => {
            this.closeAlertBox()
          }, 2000)
        }
      }

      return this.show
    },
  },
  methods: {
    async closeAlertBox() {
      clearTimeout(this.timeOutClock)
      this.timeOutClock = null
      if (this.$store.getters.alertMessage.refresh) {
        this.$store.dispatch('setLoading', false)
        window.scrollTo(0, 0)
        this.$router.go()
      } else if (this.$validate.DataValid(this.$store.getters.alertMessage.redirect)) {
        this.$store.dispatch('setLoading', false)

        if (typeof this.$store.getters.alertMessage.redirect === 'string') {
          if (this.$store.getters.alertMessage.redirect === 'login') {
            await this.forceLogout(false)
          }
          this.$router.replace({ name: this.$store.getters.alertMessage.redirect })
        } else if (
          typeof this.$store.getters.alertMessage.redirect === 'object' &&
          this.$store.getters.alertMessage.redirect['name']
        ) {
          if (this.$store.getters.alertMessage.redirect.name === 'login') {
            await this.forceLogout(false)
          }
          this.$router.replace(this.$store.getters.alertMessage.redirect)
        }
      }

      this.$store.dispatch('toggleSnack')
    },
  },
  beforeDestroy() {
    if (this.timeOutClock != null) {
      clearTimeout(this.timeOutClock)
    }
  },
}
</script>

<style scoped>
.alert-pop {
  position: fixed;
  top: 6%;
  right: 4%;
  width: 250px !important;
  z-index: 9999;
}
</style>
