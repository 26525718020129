const SET_DIALOG_LINK = 'SET_LEAVE_DIALOG_LINK';
const SHOW_DIALOG = 'SHOW_LEAVE_DIALOG';

export default {
   state: {
      showDialog: false,
      toLink: null,
   },
   actions: {
      showLeaveDialog: ({ commit }, value) => {
         commit(SET_DIALOG_LINK, value);
         commit(SHOW_DIALOG, true);
      },
      hideLeaveDialog: ({ commit }) => {
         commit(SHOW_DIALOG, false);
      },
   },
   mutations: {
      [SET_DIALOG_LINK]: (state, value) => {
         state.toLink = value;
      },
      [SHOW_DIALOG]: (state, value) => {
         state.showDialog = value;
      },
   },
}