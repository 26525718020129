<template>
  <v-navigation-drawer :value="isDrawerOpen" app floating width="260" class="app-navigation-menu" :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)">
    <!-- Navigation Items -->
    <v-list expand shaped class="vertical-nav-menu pr-5">
        <div v-for="(item, index) in navLink" :key="`group_${index}`">
          <v-subheader v-if="displayGroup(item) && item.title">{{ item.title }}</v-subheader>
          <template v-for="(subItem, i) in item.children">
            <nav-menu-link :key="`group_${index}_item_${i}`" v-if="subItem.show" :title="subItem.title" :to="{ name: subItem.name }"></nav-menu-link>
          </template>
        </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuLink
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    navLink: []
  }),
  methods: {
    initializeNavLink() {
      const permission = this.getUserPermission();
      this.navLink = [
        {
          title: '用戶',
          children: [
            {
              title: '用戶管理',
              name: 'UserList',
              show: permission.includes('user')
            },
            {
              title: '所有管理員',
              name: 'AdminList',
              show: permission.includes('admin')
            },
            {
              title: '管理員權限',
              name: 'PermissionManage',
              show: permission.includes('admin')
            }
          ]
        },
        {
          title: '工作',
          children: [
            {
              title: '工作管理',
              name: 'JobList',
              show: permission.includes('job')
            }
          ]
        },
        {
          title: '訂單',
          children: [
            {
              title: '課程訂單',
              name: 'OrderList',
              show: permission.includes('order')
            }
          ]
        },
        {
          title: '宣傳管理',
          children: [
            {
              title: '優惠券',
              name: 'CouponList',
              show: permission.includes('promotion')
            },
            {
              title: '推送通知',
              name: 'Notification',
              show: permission.includes('promotion')
            }
          ]
        },
        {
          title: '禮品',
          children: [
            {
              title: '換領預約',
              name: 'RedeemList',
              show: permission.includes('gift')
            },
            {
              title: '禮品設定',
              name: 'GiftList',
              show: permission.includes('gift')
            }
          ]
        },
        {
          title: '其他',
          children: [
            {
              title: '查詢',
              name: 'EnquiryList',
              show: permission.includes('enquiry')
            },
            {
              title: '報表',
              name: 'Report',
              show: permission.includes('report')
            },
            {
              title: '訂閱',
              name: 'SubscribeList',
              show: permission.includes('subscribe')
            },
            {
              title: '設定',
              name: 'Setting',
              show: permission.includes('user')
            }
          ]
        },
      ];
    },

    displayGroup(item) {
      return item.children.filter(el => el.show).length > 0;
    }
  },
  async created() {
    const check = await this.checkUserData()
    if (check) {
      this.initializeNavLink()
    }
  },
}
</script>

<style>
.v-navigation-drawer__content {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  max-height: 100%;
}
</style>

<style lang="scss" scoped>
@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  background-color: #fafafa !important;

  .v-list {
    flex: 1 !important;

    .v-list-item {
      &.vertical-nav-menu-link {
        ::v-deep .v-list-item__icon {
          .v-icon {
            transition: none !important;
          }
        }
      }
    }
  }
}

.vertical-nav-menu {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}

.v-application.theme--dark .app-navigation-menu {
  background-color: #272727 !important;
}

.v-subheader {
  align-items: end;
  padding: 0 22px;
}
</style>
