<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar app flat absolute color="transparent">
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon class="d-block d-lg-none me-2" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>
          <v-spacer></v-spacer>
          <!-- Right Content -->
          <app-bar-menu></app-bar-menu>
        </div>
      </div>
    </v-app-bar>

    <!-- main content of this app -->
    <v-main style="background-color: #fafafa">
      <div class="app-content-container boxed-container pa-6">
        <slot></slot>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiBellOutline, mdiGithub } from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import AppBarMenu from './components/AppBarMenu.vue'

export default {
  components: {
    VerticalNavMenu,
    AppBarMenu,
  },
  setup() {
    // https://v3.vuejs.org/api/refs-api.html
    const isDrawerOpen = ref(null)

    return {
      isDrawerOpen,

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
      },
    }
  },
  async created() {
    await this.checkUserData();
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

#current-page-meta-title {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
  color: var(--v-primary-base) !important;
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
